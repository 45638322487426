import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { activeAppInfo } from './appinfo';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private userSvc: UserService
  ) {}

  ngOnInit(): void {
      if (localStorage.getItem(activeAppInfo.tokenname)) {
        if (localStorage.getItem(activeAppInfo.floatId)) {
          this.router.navigate(["/home"]);
        } else {
          this.router.navigate(["/float"]);
        }
      } else {
        this.router.navigate(["/login"]);
      }
      // this.router.navigate(["/purchasereceipt/1"])
  }
}
