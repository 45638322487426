import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { activeAppInfo } from '../appinfo';

@Injectable({
  providedIn: 'root'
})
export class CrossHttpService {
  baseurl = activeAppInfo.baseurl;
  token = localStorage.getItem(activeAppInfo.tokenname);
  phone = localStorage.getItem("phone");
  constructor(private platform: Platform,
              private nativeHttp: HTTP,
              private httpClient: HttpClient,
              ) { 

              }
    async loadToken() {
      this.token = localStorage.getItem(activeAppInfo.tokenname);
    }      

    get(url): Observable<any> {
      this.token = localStorage.getItem(activeAppInfo.tokenname);
      url = this.baseurl + url;
    if (this.platform.is('cordova')) {
      const headers = {
        'Content-Type': 'application/json',
      };
      if (this.token) {
        headers[activeAppInfo.tokenname] = this.token;
      }
      console.log(headers, "aa")
      return Observable.create((observer: any) => {
        this.nativeHttp.get(url, {}, headers).then((res: any) => {
          if (typeof res.data === 'string') {
            res.data = JSON.parse(res.data);
          }
          observer.next(res.data);
        });
      });
    } else {
      let headers = {};
      if (this.token) {
        headers = new HttpHeaders({
          [activeAppInfo.tokenname]: this.token,
        });
      }
      return this.httpClient.get(url, {headers});
    }
  }

  nativeFileUpload(url, postData, headers) {
    if (this.platform.is('cordova')) {
      return Observable.create((observer: any) => {
        this.nativeHttp.uploadFile(url, {}, headers, [postData.url], ['file']).then(
          (res: any) => {
            console.log(res);
            if (typeof res.data === 'string' && res.data) {
              res.data = JSON.parse(res.data);
           }
            observer.next(res.data);
          }
        ).catch(
          (err: any) => {
            if (typeof err.error === 'string') {
              err.error = JSON.parse(err.error);
            }
            console.log('error here', err);
            observer.error(err);
          }
        );
      });
    }
  }

  uploadFile(url, postData) {
    return this.httpClient.post(url, postData, {reportProgress: true, observe: 'events'});
  }

  post(url, postData, isFile = false): Observable<any> {
    this.token = localStorage.getItem(activeAppInfo.tokenname);
    url = this.baseurl + url;
    if (this.platform.is('cordova')) {
      
      if (isFile) {
        const headers: any = {};
        if (this.token) {
          headers[activeAppInfo.tokenname] = this.token;
        }
         return this.nativeFileUpload(url, postData, headers);
      } else {
        const headers: any = {};
        if (this.token) {
          headers[activeAppInfo.tokenname] = this.token;
        }
        // tslint:disable-next-line: deprecation
      return Observable.create((observer: any) => {
        this.nativeHttp.setDataSerializer("json");
        this.nativeHttp.post(url, postData, headers).then((res: any) => {
          if (typeof res.data === 'string') {
             res.data = JSON.parse(res.data);
          }
          observer.next(res.data);
        }).catch(err => {
          console.log(err);
          if (typeof err.error === 'string') {
            err.error = JSON.parse(err.error);
          }
          console.log(err);
          observer.error(err);
        });
      });
      }
    } else {
      if (isFile) {
        return this.uploadFile(url, postData);
      } else {

        let headers = {};
        if (this.token) {
          headers = new HttpHeaders({
            [activeAppInfo.tokenname]: this.token
          });
        }
        // const headers = {};
        return this.httpClient.post(url, postData, {headers});
      }
    }
    // if (isFile) {
    //   return this.uploadFile(url, postData);
    // } else {
    //   return this.httpClient.post(url, postData);
    // }
  }
}