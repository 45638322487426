import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { routes } from './apiroutes';
import { CrossHttpService } from './cross-http.service';
import { map } from 'rxjs/operators';
import { activeAppInfo } from '../appinfo';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  isloggedIn$ = new BehaviorSubject(false);
  constructor(private crossHttpSvc: CrossHttpService) { }

  login(formValue: any) {
    return this.crossHttpSvc.post(routes.Login, formValue).pipe(
      map(
        (res: any) => {
          localStorage.setItem(activeAppInfo.tokenname, res.token);
          localStorage.setItem(activeAppInfo.userinfo, JSON.stringify(res.user))
          this.isloggedIn$.next(true);
          return res;
        },
        (err: any) => {
          return err;
        }
      )
    );
  }

  saveFloat(formValue) {
    return this.crossHttpSvc.post(routes.SaveFloat, formValue);
  }

  getShops() {
      return this.crossHttpSvc.get(routes.ShopList)
  }
}
