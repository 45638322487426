export const routes = {
    "Login": "app/login",
    "ShopList": "app/shops",
    "CategoryList": "app/categories",
    "SaveFloat": "app/save-session",
    "ProductList": "app/products",
    "MiscProductList": "app/misc-products",
    "PopupNotesList": "app/popup-notes",
    "TaxList": "app/taxes",
    "CustomerList": "app/customers",
    "Orders": "app/order",
    "AddCustomer": "app/add-customer",
    "OrderList": "app/orders",
    "singleOrder": "app/order",
    "businessInfo": "app/business-info",
    "refundOrderItem": "app/refund-order-item",
    "ParentCategories": "app/parentcategories"
}